var swiperHelper;

function swiperHelper(){
    let me = this;
    me.logo;
    me.swipers = {};

    me.init = (offset) => {
        me.logo = document.getElementById("par-header-logo-img");
        me.breakpoint1000 = window.matchMedia('(min-width:1000px)');
        me.breakpoint1200 = window.matchMedia('(min-width:1200px)');

        me.offset = 0;
        if(offset){
            me.offset = offset;
        }

        me.setupSwipers();
        me.initSwipers();

        window.addEventListener('MMSkinGo', () => {
            me.updateSwipers();
        }, false);

        me.breakpoint1000.addListener(me.breakpointChecker);
        me.breakpoint1200.addListener(me.breakpointChecker);
	};

    me.breakpointChecker = () => {
        setTimeout(() => {me.updateSwipers();}, 300);
    };

    me.setupSwipers = () => {
        let swiperContainers = document.querySelectorAll(".par-swiper-container");

        for(let i=0; i<swiperContainers.length; i++){
            let target = swiperContainers[i],
                name = target.dataset.swipername,
                type = target.dataset.swipertype,
                load = target.dataset.swiperload
                callback = target.dataset.swipercallback || false;

            me.swipers[name] = {
                "target": target,
                "type": type,
                "load": load,
				"callback": callback
            };
        }

        me.setSlides();
    };

    me.setSlides = () => {
        for(let name in me.swipers){
            let type = me.swipers[name].type;
            let load = me.swipers[name].load;

            switch(type){
                case "main":
                    me.setMainSlides(name);
                break;

                case "showcase":
                    me.setShowcaseSlides(name);
                    break;

                case "default":
                    me.setDefaultSlides(name);
                break;
            }
        }
    };

    me.setDefaultSlides = (name) => {
        me.swipers[name]["slides"] = me.getSlidesFromDOM(name);
    };

    me.getSlidesFromDOM = (name) => {
        var slides = [];
        let target = me.swipers[name].target;
        let slidesDOM = [].slice.call(target.querySelectorAll(":scope > .par-swiper-slide"));
        for(var i=0; i<slidesDOM.length; i++){
            var slide = slidesDOM[i];
            slides.push(slide.outerHTML);
        }

        return slides;
    };

    me.initSwipers = () => {
        for(var name in me.swipers){
            if(!me.swipers[name].isSetted && me.swipers[name].load == "default"){
                var target = me.swipers[name].target;
                me.swipers[name]["swiper"] = me.initSwiper(target);
            }
        }

        me.processLazySwipers();
        window.addEventListener('scroll', function() {
			me.processLazySwipers();
		});
    };

    me.processLazySwipers = () => {
        for(var name in me.swipers){
            if(!me.swipers[name].isSetted && me.swipers[name].load == "lazy"){
                var target = me.swipers[name].target;
                if(me.toProcessLazySwipers(target) && !target.classList.contains('processed')){
                    me.swipers[name]["swiper"] = me.initSwiper(target);
                    target.classList.add('processed');
                }
            }
        }
    };

    me.toProcessLazySwipers = (element) => {
        var rect = element.getBoundingClientRect();
        var html = document.documentElement;
		return (
			(rect.top - me.offset) <= (window.innerHeight || html.clientHeight) && (rect.top + rect.height) >= 0
		);
    };

    me.setMainSlides = (name) =>{
        let swiper = me.swipers[name];

		if(swiper.swiper === undefined){
            swiper["swiper"] = me.initMainSwiper(swiper.target);
            swiper["isSetted"] = true;
            swiper.target.classList.add("par-swiper-dom-loaded");
        }
    };

    me.setShowcaseSlides = (name) =>{
        let swiper = me.swipers[name];

        if(swiper.swiper === undefined){
            swiper["swiper"] = me.initShowcaseSwiper(swiper.target);
            swiper["isSetted"] = true;
        }
    };

    me.initSwiper = (target, loop) => {
        var swiper = new Swiper(target, {
            breakpointsInverse: true,
            speed: 500,
            navigation: {
                nextEl: target.parentNode.querySelectorAll(':scope .par-arrow-button-next'),
                prevEl: target.parentNode.querySelectorAll(':scope .par-arrow-button-prev')
            },
            pagination: {
                el: '.par-swiper-pagination',
                type: 'fraction'
            },
            controller: {
                control:  target.dataset.swipercontrolled ? me.getSwipers()[target.dataset.swipercontrolled].swiper : undefined,
                inverse: false,
                by: 'slide'
            },
            loop: target.dataset.swiperloop ? target.dataset.swiperloop : false,
            slidesPerView: "auto",
			slidesPerGroup: 1,
            calculateHeight:true,
            autoHeight:true,
            simulateTouch: false,
            observeParents: true,
            on: {
                init: function () { 
                    // init eventuale controllo reciproco per istanza dida gallery
                    if (target.dataset.swipercontrolled) {
                        // check if initialized
                        let ctrlinstance = swiperHelper.swipers[target.dataset.swipercontrolled];
                        let checkctrl = () => { 
                            if (typeof ctrlinstance !== 'undefined') {
                                // console.debug(ctrlinstance);
                                me.swipers[target.dataset.swipercontrolled].swiper.controller.control = target.swiper;
                                clearInterval(ctrlchecker);

                                setTimeout(function(){
                                    initAdvSlides();
                                },1000);
                            }
                        }
                        const ctrlchecker = setInterval(checkctrl, 200);
                    }
                },
            },
        });

        return swiper;
    };

    me.initMainSwiper = (target) => {
        var isHome = target.dataset.ishome ? target.dataset.ishome : false;
        let swiper = new Swiper(target, {
            breakpointsInverse: true,
			speed: 1000,
            effect: 'fade',
            fadeEffect: { crossFade: true },
			autoplay: {
				delay: 6000,
				disableOnInteraction: false
			},
			navigation: {
                nextEl: target.parentNode.querySelectorAll(':scope .par-arrow-button-next'),
                prevEl: target.parentNode.querySelectorAll(':scope .par-arrow-button-prev')
            },
            pagination: {
                el: target.parentNode.querySelectorAll(':scope > .par-swiper-pagination'),
                type: 'bullets',
                clickable: true,
            },
            loop: true,
			updateOnImagesReady: true,
            slidesPerView: 1,
            centeredSlides: true,
            simulateTouch: false,
            breakpoints: {
                1000: {
					loop: true
                },
                1200: {
					loop: true
                }
            },
            on: {
                transitionStart: () => {
                    if(isHome){}
                }
            }
        });

        return swiper;
    };

    me.initShowcaseSwiper = (target, loop) => {
        var swiper = new Swiper(target, {
            breakpointsInverse: true,
            speed: 500,
            navigation: {
                nextEl: target.parentNode.querySelector(':scope .par-arrow-button-next'),
                prevEl: target.parentNode.querySelector(':scope .par-arrow-button-prev'),
            },
            pagination: {
                el: target.parentNode.querySelector(':scope .par-swiper-pagination'),
                type: 'bullets',
                clickable: true,
            },
            controller: {
                control:  target.dataset.swipercontrolled ? me.getSwipers()[target.dataset.swipercontrolled].swiper : undefined,
                inverse: false,
                by: 'slide'
            },
            loop: target.dataset.swiperloop || false,
            slidesPerView: "auto",
            slidesPerGroup: 1,
            simulateTouch: false,
            observeParents: true,
            on: {
                init: function () {
                    // init eventuale controllo reciproco per istanza dida gallery
                    if (target.dataset.swipercontrolled) {
                        // check if initialized
                        let ctrlinstance = swiperHelper.swipers[target.dataset.swipercontrolled];
                        let checkctrl = () => {
                            if (typeof ctrlinstance !== 'undefined') {
                                // console.debug(ctrlinstance);
                                me.swipers[target.dataset.swipercontrolled].swiper.controller.control = target.swiper;
                                clearInterval(ctrlchecker);
                                setTimeout(function(){
                                    initAdvSlides();
                                },1000);
                            }
                        }
                        const ctrlchecker = setInterval(checkctrl, 200);
                    }
                },
            },
        });

        return swiper;
    };

    me.getSwipers = function(){
		return me.swipers;
	};

    me.updateSwipers = () => {
        for(const i in me.swipers){
            me.swipers[i].swiper.update();
        }
    };
}
